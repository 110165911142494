<template>
  <div class="a3-login-container">
    <div class="background-image"></div>
    <div class="login-inner">

      <div class="sso-dropdown" 
           style="display: flex; justify-content: end; margin-top: 20px; margin-bottom: 20px; position: relative;"
          >
          <div class="sso-dropdown-button"
               @click="dropdown = !dropdown"
              >
              <i class="fas fa-ellipsis-v"></i> 
          </div>

          <div class="sso-dropdown-dropdown" 
               @mouseleave="dropdown = false;"
               @click="$router.push('sso')"
               v-if="dropdown"
              >
              Inloggen met Single-Sign On
          </div>
      </div>

      <div style="text-align: right">
        <img src="@/public/img/a3_online_logo.png" class="login-logo"/>
      </div>



      <div class="element-form">

        <div class="login-form-field" v-if="!show_two_factor">
          <label><i class="fas fa-envelope"></i>
          {{ $t('ui.login.mail') }} </label>
          <input v-model="login.mail"
            class="a3-input" type="email" placeholder="naam@organisatie.nl"/>
        </div>

        <div class="login-form-field" v-if="!show_two_factor">
          <label><i class="fas fa-lock"></i>
          {{ $t('ui.login.password') }} </label>
          <input v-model="login.password" class="a3-input" type="password" placeholder="**********" />
        </div>

        <div class="login-form-field" v-if="show_two_factor">
            <label><i class="fas fa-mobile-android-alt"></i> {{ $t('ui.login.two_factor_token') }}</label>
            <input v-model="login.two_factor_token" class="a3-input" type="number" placeholder="123 456" />
        </div>

        <InlineErrorBox v-if="error_key != null" :error_key="error_key" />

        <div class="form-buttons">
          <button type="submit" class="login-button" @click="handleSubmit">
            {{ $t('ui.login.login') }} <i class="fas fa-chevron-right"></i>
          </button>
          <span class='login-help-button'
                        @click="$router.push('/request_reset_password')"
            >
            {{ $t('ui.login.forgot_password')}}
          </span>
        </div>
      </div>
      <LoadingOverlay :loading="loading" />
      <ErrorOverlay v-if="show_license_error"
                    title="Uw licentie is verlopen"
                    @close="continueRedirect"
      >
        Je kunt nog wel al je A3'tjes bekijken, maar niet meer
        bewerken, exporteren of je organisatie aanpassen.

        <p>
          Wil je graag verder? Je kunt A3 online bestellen via de bestelpagina.
          <a href="https://www.a3online.nl/bestellen" target="_blank">
            www.a3online.nl/bestellen
          </a>
        </p>
      </ErrorOverlay>

    </div>
  </div>
</template>

<script src="https://www.google.com/recaptcha/api.js?render=6LdIrIUqAAAAAKUYy1jE1UD9Dn-svC0l3e4EXeSK" async defer></script>

<script>

import Vue from 'vue';
import InlineErrorBox from '@/components/elements/InlineErrorBox.vue';

export default {
  components: {
    InlineErrorBox,
  },

  data: () => ({
    login: {
      mail: '',
      password: '',
      two_factor_token: null,
    },
    dropdown: false,
    loading: false,
    error_key: null,
    show_license_error: false,
    show_two_factor: false,
    siteKey: "6LdIrIUqAAAAAKUYy1jE1UD9Dn-svC0l3e4EXeSK",
  }),

  mounted() {
    // Check if the script is already loaded
    if (!document.querySelector('script[src="https://www.google.com/recaptcha/api.js?render=6LdIrIUqAAAAAKUYy1jE1UD9Dn-svC0l3e4EXeSK"]')) {
      const script = document.createElement('script');
      script.src = `https://www.google.com/recaptcha/api.js?render=${this.siteKey}`;
      script.async = true;
      script.defer = true;
      document.head.appendChild(script);
    }
  },

  methods: {
    async handleSubmit() {
      // Execute reCAPTCHA and get a token
      window.grecaptcha.ready(async () => {
        const token = await window.grecaptcha.execute(this.siteKey, { action: 'submit' });
        // Send the token to your backend for verification
        this.$http.post('recaptcha/verify',{token: token} )
          .then((res) => res.json())
          .then((data) => {
            if (data.success) {
              this.doLogin();
            } else {
              alert('reCAPTCHA verification failed.');
            }
          });
      });
    },

    doLogin() {
      if (this.login.mail === '' || this.login.password === '') {
         this.error_key = 'auth.invalid_login';
         return;
      }

      this.error_key = null;
      this.loading = true;
      this.$http.post('login', this.login)
        .then(res => {
          if (res.body.message === "2fa-required") {
              this.show_two_factor = true;
              this.loading = false;
              return;
          }

          this.loading = false;
          this.$store.commit('auth/setOrganisation', res.body.organisation);
          this.$store.commit('auth/setPerson', res.body.person);
          this.$store.commit('auth/setAccount', res.body.account);
          this.$store.commit('auth/setFeatures', res.body.features);
          this.$store.commit('auth/setLicense', res.body.license);

          this.$store.commit('settings/loadSettings', res.body.settings);

          // Set the proper tokens
          window.localStorage.setItem('auth_token', res.body.token);
          Vue.http.headers.common['Authorization'] = `Bearer ${res.body.token}`;

          // If the license is null, show the proper error
          if (res.body.license == null) {
            this.show_license_error = true;
            return;
          }

          this.loadNotificationCount();

          this.$router.push('/a3');
        }, err => {
          this.loading = false;
          if (err.status == 0) { this.error_key = 'general.not_available'; }
          else if (err.status == 401) { this.error_key = 'auth.invalid_login'; }
          else if (err.status == 500) { this.error_key = 'general.server_error'; }
          else { this.error_key = 'general.generic'; }
        })
    },

    loadNotificationCount() {
      return this.$http.get('notifications/count_unread')
        .then((res) => {
          this.$store.commit('settings/setNotificationCount', res.body);
        })
    },

    continueRedirect() {
      this.$router.replace('/a3');
    },
  }
}
</script>


<style lang="scss">

@import "@/assets/variables.scss";

.a3-login-container {
  background-color: darken($a3-green, 10%);
  margin: 0;
  position: relative;

  height: calc(100vh - 0px);
  width: calc(100vw - 0px);

  .background-image {
    background-image: url('../public/img/a3_login_background.png');
    background-size: cover;
    opacity: .6;
    filter: blur(8px);
    height: 100%;
  }

  @media (max-width: 680px) {
    .login-inner {
      left: 5px;

      label {
        margin-top: 20px;
        margin-bottom: 10px;
        width: 100%;
        display: block;
        text-align: left;
      }

    }
  }
  @media (min-width: 681px) {
    .login-inner {
      left: calc((100% - 600px) / 2);

      label {
        margin-top: 20px;
        margin-bottom: 10px;
        vertical-align: middle !important;
        width: 150px !important;
      }
    }

  }


  .login-inner {
    position: absolute;
    top: 90px;
    background-color: white;
    width: calc(100% - 70px);
    max-width: 600px;
    min-width: 250px;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
    padding: 30px;
    border-radius: 10px;

    -webkit-box-shadow: 0px 0px 52px 2px rgba(0,0,0,0.49);
    -moz-box-shadow: 0px 0px 52px 2px rgba(0,0,0,0.49);
    box-shadow: 0px 0px 52px 2px rgba(0,0,0,0.49);

    .form-buttons {
      text-align: center;
      padding-left: 0;
    }
  }

  .a3-input {
    padding: 15px;
    width: calc(100% - 30px - 2px);
    margin: 0;
    vertical-align: middle;

    border-radius: 5px;
    border: 2px rgba($a3-green, .2) solid;
    transition: border-color .1s ease-out;
    &:focus {
      outline: 0;
      border: 2px rgba($a3-green, 1) solid;
    }
  }

  text-align: center;

  h2 {
    margin: 0;
    padding: 0;
    margin-bottom: 10px;
    padding-left: 165px;
    color: $a3-green;
  }

  .input-container {
    margin-top: 30px;

    h3 {
      margin-bottom: 4px;
    }
  }
}
.login-help-button {
  font-size: .8em;
  color: $a3-green;
  margin-left: 15px;
  cursor: pointer;

  &:hover {
    color: lighten($a3-green, 10%);
    text-decoration: underline;
  }
}


.login-logo {
  max-height: 90px;
  margin-top: 20px;
}
.login-button {
  padding: 10px 30px;
  display: inline-block;
  text-align: center;
  background-color: $a3-green;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  border: 2px $a3-green solid;

  &:hover {
    background-color: white;
    color: $a3-green;
  }
}



.sso-dropdown-button {
    opacity: .8;
    color: $a3-green;
    border: 1px rgba($a3-green, .4) solid;
    width: 20px;
    text-align: center;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
          background-color: $a3-green;
          color: white;
    }
}

.sso-dropdown-dropdown {
    position: absolute;
    top: 100%;

      padding: 10px 30px;
      display: inline-block;
      text-align: center;
      background-color: $a3-green;
      color: white;
      border-radius: 5px;
      cursor: pointer;
      border: 2px $a3-green solid;

      &:hover {
        background-color: white;
        color: $a3-green;
      }
}
</style>


