<template>
  <WithMenuContainer>
    <div class="container">
        <h1>{{ $t('ui.settings_page.signed_in_as', [person.name]) }} </h1>

      <button class="a3-button action"
        @click="$router.push('/me/settings/preferences')">
          <i class="fas fa-cog"></i> {{ $t('ui.user_settings.user_settings') }}
      </button>

      <button class="a3-button action" @click="doLogout">
          <i class="fas fa-sign-out"></i> {{ $t('ui.settings_page.logout') }}
      </button>

      <button class="a3-button danger" @click="doLogoutAllDevices">
          <i class="fas fa-sign-out"></i> {{ $t('ui.settings_page.logout_all') }}
      </button>

      <h2>{{ $t('ui.settings_page.my_account') }} </h2>

      <div class="user-info-card">
        <i class="fas fa-sitemap"></i> <h3>{{ organisation.name }}</h3>
      </div>
      <div class="user-info-card">
        <i class="fas fa-user"></i> <h3>{{person.name}} ( {{ person.initials }})</h3>
        <p><em>
          {{ person.function }}
        </em></p>
      </div>
      <div class="user-info-card" v-if="license != null">
          <i class="fas fa-badge"></i> <h3>Licentie</h3>
          <p>
              <strong>Licentie:</strong> {{ $t(`license.types.${license.license_type}`) }} <br>
              <strong>Aantal jaarplannen</strong> {{ license.max_plans }}<br>
              <strong>Start datum:</strong> {{ $d(new Date(license.start_date), 'short')}}<br>
              <strong>Eind datum:</strong> {{ $d(new Date(license.end_date), 'short')}}
          </p>
      </div>

      <template v-if="account.advisor_account">
          <h2>
              Adviseur profiel
              <InfoBox tkey="advisor_profile" />
          </h2>

          <div class="advisor-profile">
              <ProfileRender
                 v-if="advisor_profile"
                :advisor_profile="advisor_profile"
                 :account="account"
                 :person="person"
              />

              <button class="a3-button action"
                      @click="$router.push('/advisor/profile/edit')"
                  >
                  <i class="fas fa-pencil"></i>
                  Profiel bewerken
              </button>
          </div>

      </template>
    </div>
  </WithMenuContainer>
</template>


<script>

import Vue from 'vue';
import FeaturesView from '@/components/admin/FeaturesView.vue';
import LanguageSelector from '@/components/nav/LanguageSelector.vue';

import ProfileRender from '@/components/advisor/ProfileRender.vue';

export default {

  components: {
    FeaturesView,
    LanguageSelector,
    ProfileRender,
  },

  data: () => ({
      advisor_profile: null,
  }),

  computed: {
    organisation() {
      return this.$store.getters['auth/organisation'];
    },
    person() {
      return this.$store.getters['auth/person'];
    },
    account() {
      return this.$store.getters['auth/account'];
    },
    features() {
      return this.$store.getters['auth/features'];
    },
    license() {
      return this.$store.getters['auth/license'];
    },
  },

    mounted() {
        if (this.account.advisor_account) {
            this.loadAdvisorProfile();
        }
    },

  methods: {
    doLogoutLocal() {
      window.localStorage.removeItem('auth_token');
      delete Vue.http.headers.common['Authorization'];

      this.$store.commit('auth/setPerson', null);
      this.$store.commit('auth/setOrganisation', null);
      this.$store.commit('auth/setAccount', null);
      this.$store.commit('auth/setFeatures', null);

      this.$router.push('/login');
    },

    doLogoutAllDevices(){
      if(confirm(this.$t('ui.settings_page.logout_all_confirm'))){
        const token = window.localStorage.getItem('auth_token');
        this.$http.post('logout',{
          'token': token,
          'account_id': this.account.id,
          'logout_all':'true',
        }).then((res) => {
          this.doLogoutLocal();
        })
      }
    },

    doLogout(){
      const token = window.localStorage.getItem('auth_token');
      this.$http.post('logout',{
        'token': token,
        'account_id': this.account.id,
        'logout_all':'false',
      }).then((res) => {
        this.doLogoutLocal();
      })
    },

    resetOrganisation() {
      delete Vue.http.headers.common['A3-Organisation-Id'];
      this.$http.get('me').then((res) => {
        this.$store.commit('auth/setOrganisation', res.body.organisation);
        this.$store.commit('auth/setOrganisationImpersonated', false);
      })
    },

    loadAdvisorProfile() {
        this.$http.get('advisor/profile').then((res) => {
            this.advisor_profile = res.body;
        });
    },
  }
}
</script>

<style lang="scss">

.admin-links {
  li:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}
</style>
