<template>
  <WithReportMenuContainer :a3doc_id="a3document_id" :filter_slug="filter_slug">
        <h1 class="report-title">
            <i class="fas fa-analytics"></i>
            {{ $t('ui.report.pi_dashboard') }}</h1>
        <h2 class="report-a3-name">{{ a3_title }}</h2>
        <ReportComment :a3doc_id="this.a3document_id" a3report="kpiDashboard" />
      <FilterPrint :filter="filterOptions"
         v-if="a3_document != null"
         :a3_document="a3_document"
      />

      <template v-if="a3_document != null">
        <A3KpiDashboard
               v-for="area in a3_document.meta.layout.structure.result_areas.map(x => x.key)"
               :filterMap="filterMap"
               :key="area"
               :relations="relations"
               :result_area="area"
               :a3_document="a3_document" />
      </template>
      <PrintComments :a3doc_id="this.a3document_id" a3report="kpiDashboard" />
  </WithReportMenuContainer>
</template>

<script>

import FilterSlugUrl from '@/mixins/FilterSlugUrl';
import FilterPrint from '@/components/elements/FilterPrint.vue';

import ReportComment from '@/views/report/comments.vue';
import PrintComments from '@/views/report/printComments.vue';

import A3KpiDashboard from '@/components/reports/A3KpiDashboard.vue';
import WithReportMenuContainer from '@/views/WithReportMenuContainer.vue';

export default {
  mixins: [FilterSlugUrl],

  components: {
    A3KpiDashboard,
    WithReportMenuContainer,
    FilterPrint,
    ReportComment,
    PrintComments,
  },

  props: {
    a3document_id: {
      type: String,
      required: true,
    },
  },

  data: () => ({
      a3_document: null,
      relations: {parents: [], children: []},
      filterMap: null,
      filterOptions: null,
  }),

  computed: {
    organisation() {
      return this.$store.getters['auth/organisation'];
    },
    a3_title() {
        if (this.a3_document == null) return null;
        let meta = this.a3_document.meta;

        if (this.a3_document.meta.department != null) {
            return `${meta.department.name}, ${meta.timeframe.name}`;
        }

        return `${meta.organisation.name}, ${meta.timeframe.name}`;
    },
  },

  mounted() {
    this.loadA3();
    this.loadRelations();
  },

  methods: {
    loadA3() {
      this.$http.get(`a3/${this.a3document_id}`)
        .then(res => {
            this.a3_document = res.body;
            this.filterOptions = this.getFilterOptions(this.filter_slug);
            this.filterMap = this.filterMapFromOptions(this.filterOptions);
        });
    },

    loadRelations() {
      this.$http.get(`a3/${this.a3document_id}/relations`)
        .then(res => {
          this.relations = res.body;
        });
    },
  },
};
</script>
