<template>
    <div>
        <span class="comment_button" @click="openCommentBox()"><i class="fa fa-comments"></i></span>
        
        <div class="comment_box">
            <span class="close_comment_button fas fa-times" @click="closeCommentBox()"></span>
            <div class="comment_section">
                <div class="loaded_comments">
                    <div class="comment" v-for="singleComment in comments">
                        <p class="date_name"><span class="date">{{ convertDateTime(singleComment.comment.created_at) }}</span> - <span class="person"> {{ singleComment.person.name }}</span><i @click="deleteComment(singleComment.comment.id)" class="fa fa-trash delete"></i> </p>
                        <p class="actualComment">
                            <span class="data" style="display: none; white-space: pre;" :id="'comment_data_'+singleComment.comment.id">{{ singleComment.comment.comment }}</span>
                            <span class="trigger" :id="'comment_trigger_'+singleComment.comment.id" @click="showComment(singleComment.comment.id)">{{ $t("ui.report.show_note") }}</span>
                        </p>
                    </div>
                </div>
                <h3 style="color:white;">{{ $t("ui.report.note") }}</h3>
                <label @click="loadStandardLayout()" class="add_standard_layout">{{ $t('ui.report.insert_standard_layout') }}</label>
                <textarea v-model="comment">
                    
                </textarea>
                <button class="a3-button" style="margin-right: 0;" @click="saveComment()">{{  $t('ui.general.save') }}</button>
            </div>
        </div>
    </div>
</template>
  
<style lang="scss">

@media screen and (max-width: 2378px) {
  .comment_report_div{  
    margin-right: 25% !important;
  }
}
@media screen and (max-width: 1268) {
    .comment_report_div_result{  
        margin-right: 25% !important;
    }
}

// .loaded_comments {
//     -ms-overflow-style: none;  /* Internet Explorer 10+ */
//     scrollbar-width: none;  /* Firefox */
// }
// .loaded_comments::-webkit-scrollbar { 
//     display: none;  /* Safari and Chrome */
// }

.add_standard_layout{
    color:white;
    text-decoration: underline;
}
.add_standard_layout:hover{
    cursor: pointer;
}
.loaded_comments{
    max-height: 350px;
    overflow-y: auto;
}
.close_comment_button{
    position: absolute;
    right: 25px;
    z-index: 9999;
    top: 115px;
    color: white;
    font-size: 20px;
}
.close_comment_button:hover{
    cursor: pointer;
    color:whitesmoke;
}
.comment_box{
    position: fixed;
    right: 0;
    top: 0;
    display: none;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
    width: calc(25% + 17px);
    background:#007385;
    z-index: 1;
}
.comment_button{
    background: #007385;
    padding: 10px;
    color: white;
    font-size: 28px;
    position: absolute;
    right: 35px;
    top: 17px;
    display: flex;
    align-items: center;
    flex-flow: column;
    justify-content: center;
    border-radius: 10%;
}
.comment_button:hover{
    cursor: pointer;
    padding:12px;
    top: 16px;
    right: 34px;
}

.comment_section{
    position: fixed;
    right: 17px;
    top: 150px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-height: calc(100vh - 170px);
    width: calc(25% - 17px);
}
.comment_section textarea{
    flex: 2;
    min-height: 250px;
}
.comment{
    border-width: 2px;
    border-color: #004752;
    border-style: solid;
    margin-bottom: 6px;
}
.comment p.date_name{
    padding: 6px;
    color:white;
    background-color: #004752;
}
.comment p.actualComment{
    padding: 0px 6px 6px 6px;
    display: flex;
    flex-direction: column;
}
.comment p.actualComment span.trigger{
    text-align: center;
}
.comment p.actualComment span.trigger:hover{
    cursor: pointer;
    color: #004752;
}
.delete{
    color:#dd0000;
    margin-left:8px;
}
.delete:hover{
    cursor: pointer;
    color:white;
}
</style>
<script>
import { DateTime } from 'luxon';

export default {
    
    props: {
        a3doc_id: {
            type: Number,
            required: true
        },
        a3report: {
            type: String,
            required: true
        },
    },

    data: () => ({
        comment: "",
        comments: []
    }),

    mounted() {
        this.getComments();
        this.hideAllComments();
    },
    methods: {
        openCommentBox(){
            const button = document.getElementsByClassName("comment_button");
            const box = document.getElementsByClassName("comment_box");

            button[0].style.display = "none";
            box[0].style.display = "flex";
        },
        loadStandardLayout(){
            this.comment += this.$t('ui.report.standard_layout_input');
        },
        closeCommentBox(){
            const button = document.getElementsByClassName("comment_button");
            const box = document.getElementsByClassName("comment_box");

            button[0].style.display = "flex";
            box[0].style.display = "none";
        },
        saveComment(){
            if(confirm(this.$t('ui.report.save_note_message'))){
                this.$http.post('report/comment', {
                    a3_id: parseInt(this.a3doc_id),
                    report: this.a3report,
                    person_id: this.$store.getters['auth/person'].id,
                    comment:this.comment
                }).then(() => {
                    
                    this.comment = "",
                    this.getComments()
                });
            }
        },
        getComments(){
            this.$http.get(`report/comments/${this.a3doc_id}?report=${this.a3report}`)
            .then((res) => {
                this.comments = res.body;
            });
        },
        convertDateTime(datetime){
            
            const cetTime = DateTime.fromISO(datetime, { zone: 'utc' })
            .setZone('Europe/Amsterdam')  // Convert to Amsterdam time zone (CET/CEST)
            .toFormat('dd-MM-yyyy');  // Format the output

            return cetTime;
        },
        hideAllComments(){
            const elements_data = document.getElementsByClassName('data');
                Array.from(elements_data).forEach(element => {
                element.style.display = 'none';
            });
            const elements_trigger = document.getElementsByClassName('trigger');
                Array.from(elements_trigger).forEach(element => {
                element.innerHTML = this.$t('ui.report.show_note');
            });
        },
        showComment(id){
            const element_trigger = document.getElementById('comment_trigger_'+id);

            if(element_trigger.innerHTML != this.$t('ui.report.hide_note')){
                this.hideAllComments();
                const element_data = document.getElementById('comment_data_'+id);
                element_data.style.display = 'block';

                element_trigger.innerHTML = this.$t('ui.report.hide_note');
            }
            else{
                this.hideAllComments();
            }
 
        },
        deleteComment(id){
            if(confirm(this.$t('ui.report.delete_note_message'))){
                this.$http.delete(`report/comment/${id}/delete`)
                .then(() => {
                    this.hideAllComments();
                    return this.getComments();
                });
            }
        }
    }
}
</script>