<template>
<div>
    <div id="print_comment_block">
        <h2>Notities</h2>
        <div class="print_comment" v-for="comment in comments">
            <div class="print_comment_header">
                <i>{{ convertDateTime(comment.comment.created_at) }}</i> - <i>{{ comment.person.name }}</i>
            </div>
            <div class="print_comment_content">
                {{ comment.comment.comment }}
            </div>
        </div>
    </div>
</div>
</template>

<style>
#print_comment_block{
    display: none;
}
.print_comment{
    border-width: 2px;
    border-color: #007385;
    border-style: solid;
    margin-bottom: 6px;
}
.print_comment_header{
    border-width: 0px 0px 2px 0px;
    border-color: #007385;
    border-style: solid;
    padding: 5px;
}
.print_comment_content{
    padding: 5px;
}
</style>

<script>
import { DateTime } from 'luxon';

export default {
    
    props: {
        a3doc_id: {
            type: Number,
            required: true
        },
        a3report: {
            type: String,
            required: true
        },
    },

    data: () => ({
        comments: []
    }),

    mounted() {
        this.getComments();
    },
    methods: {
        getComments(){
            this.$http.get(`report/comments/${this.a3doc_id}?report=${this.a3report}`)
            .then((res) => {
                this.comments = res.body;
            });
        },
        convertDateTime(datetime){
            
            const cetTime = DateTime.fromISO(datetime, { zone: 'utc' })
            .setZone('Europe/Amsterdam')  // Convert to Amsterdam time zone (CET/CEST)
            .toFormat('dd-MM-yyyy');  // Format the output

            return cetTime;
        },
    }
}
</script>