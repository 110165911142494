<template>
    <div style="height: 100%;" class="blue-scroll-bar">
        <MainMenu />
        <ReportMenu :a3doc_id="a3doc_id" :filter_slug="filter_slug" />

        <div class="view-container report-container" style="position: relative;">
            <slot></slot>
        </div>
    </div>
</template>

<script>

import MainMenu from '@/components/nav/MainMenu.vue';
import ReportMenu from '@/components/nav/ReportMenu.vue';

export default {
    components: {
        MainMenu,
        ReportMenu,
    },

    props: {
      a3doc_id: {
        type: String,
        required: true,
      },

      filter_slug: {
        type: String,
        required: false,
      },
    },
};
</script>

