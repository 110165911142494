<template>
  <div id="a3-report-menu-container">
    <span class="menu-title-container">
      <strong style="color: white; padding-right: 30px;">{{ $t('ui.nav.reports') }} :</strong>
    </span>

    <div
      v-for="(l, link_index) in links"
      :key="'main-menu-link-' + link_index"
      class="a3-tab"
      :class="{'active': $route.path.startsWith(l.active_slug)}"
    >
      <div
        :id="'menu-bar-' + l.id"
        @click="gotoReportUrl(l.url)"
      >
        <i style="margin-right: 5px" class="fas" :class="'fa-' + l.icon" />
            {{ $t(l.name) }}
      </div>
    </div>

    <div
        style="float: right"
        class="a3-tab"
         @click="triggerPrint()"
        >
        <i class="fas fa-print"></i> Print
    </div>
  </div>
</template>

<script>
export default {
    name: 'MainTabMenu',
    props: {
      a3doc_id: {
        type: String,
        required: true,
      },
        filter_slug: {
            type: String,
            required: false,
        }
    },
    data: () => ({
      links: [],
    }),
    mounted() {
        this.links = [
            {
                url: `/a3/${this.a3doc_id}`,
                name: 'ui.nav.back_to_a3',
                id: 'back-a3',
                icon: 'chevron-left',
                active_slug: '/none',
            },
            {
                url: `/report/${this.a3doc_id}`,
                name: 'ui.nav.all_reports',
                id: '',
                icon: 'list',
                active_slug: `/report/${this.a3doc_id}`,
            },
            {
                url: `/report/a3/${this.a3doc_id}`,
                name: 'ui.nav.a3_conversation',
                id: 'my-teams',
                icon: 'comments',
                active_slug: '/report/a3',
            },
            {
                url: `/report/result_area/${this.a3doc_id}`,
                name: 'definitions.terms.result_area',
                id: 'my-teams',
                icon: 'bullseye-arrow',
                active_slug: '/report/result_area',
            },
            {
                url: `/report/org_area/${this.a3doc_id}`,
                name: 'definitions.terms.org_area',
                id: 'my-users',
                icon: 'tasks',
                active_slug: '/report/org_area',
            },
            {
                url: `/report/kpi_dashboard/${this.a3doc_id}`,
                name: 'ui.nav.kpi_dashboard',
                id: 'pi-dashboard',
                icon: 'analytics',
                active_slug: '/report/kpi_dashboard',
            },
        ];
      if (this.$hasFeature('Budget')) {
        this.links.push({
              url: `/budget/a3/${this.a3doc_id}`,
              name: 'ui.budget.budget',
              id: 'a3-budget',
              icon: 'sack-dollar',
              active_slug: '/budget/a3',
          });
      }
    },

    methods: {
        gotoReportUrl(url) {
            let u = url;
            if (this.filter_slug != null) {
                u += `?filter=${this.filter_slug}`;
            }
            this.$router.push(u);
        },

        triggerPrint() {
            let comment_section = document.getElementsByClassName('comment_box');
            let comment_section_is_hidden = (comment_section[0].style.display == '');
            console.log(comment_section_is_hidden, comment_section[0].style.display);
            if(comment_section[0]){
              comment_section[0].style.display = 'none';
            }
            let comment_print_block = document.getElementById('print_comment_block');
            if(comment_print_block){
              comment_print_block.style.display = 'block';
            }
            window.print();
            if(comment_section[0] && !comment_section_is_hidden){
              comment_section[0].style.display = 'flex';
            }
            if(comment_print_block){
              comment_print_block.style.display = 'none';
            }
        },
    },
};
</script>

<style lang="scss">
@import "@/assets/variables.scss";

#a3-report-menu-container {
    background-color: $a3-green;
    border-bottom: 1px $green-darkened solid;
    user-select: none;
    -webkit-box-shadow: 0px 10px 11px -10px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 10px 11px -10px rgba(0,0,0,0.75);
    box-shadow: 0px 10px 11px -10px rgba(0,0,0,0.75);

    position: relative;
    z-index: 1000;
    animation: slideDown .2s ease-out;

    .menu-title-container {
      padding-left: 140px;
    }
}

@media screen and (max-width: 980px) {
  #a3-report-menu-container .a3-tab {
    display: block !important;
    width: 100%;
    padding-left: 0;

  }
  #a3-report-menu-container{
    .menu-title-container {
      padding-left: 0;
      padding: 10px;
      text-align: center;
      width: 100%;
      display: block;
    }
  }
}

@media print  {
  #a3-report-menu-container {
    display: none !important;
  }
}

@keyframes slideDown {
  0% { transform: translateY(-50px); }
  100% { transform: translateY(0); }
}


.a3-tab-subitems {
    position: absolute;
    z-index: 17000;
    background-color: $a3-green;
    left: 0;
    pointer-events: none;
    text-align: left;
    display: block;
    border-radius: 0px 0px 5px 5px;
    transition: opacity .2s;
    opacity: 0;
}
.a3-tab-subitem {
    padding: 0px 0px 0px 15px;
    width: 200px;
    i {
        padding-right: 5px;
        transition: padding-right .1s ease-out;
    }
    &:last-child {
        border-radius: 0px 0px 5px 5px;
    }
    &:hover, &.active {
        background-color: $green-darkened;
        color: white;
        i {
            padding-right: 10px;
        }
    }
}

.a3-mini-action {
    display: inline-block;
    color: white;
    width: 30px;
    height: 30px;
    margin-top: 10px;
    line-height: 30px;
    border-radius: 15px;
    cursor: pointer;
    text-align: center;

    &:hover {
        color: $a3-green;
        background-color: white;
    }
}

#main-tab-menu-right {
    display: inline-block;
    float: right;
}

</style>
