<template>
  <div id="app">
    <router-view />
  </div>
</template>


<script>

export default {


    data: () => ({
        notificationInterval: null,
    }),

    mounted() {
        this.setNotificationInterval();
        document.addEventListener("visibilitychange", this.onVisibilityChange);
        // Check if the script is already loaded
        if (!document.querySelector('script[src="https://www.google.com/recaptcha/api.js?render=6LdIrIUqAAAAAKUYy1jE1UD9Dn-svC0l3e4EXeSK"]')) {
        const script = document.createElement('script');
        script.src = `https://www.google.com/recaptcha/api.js?render=6LdIrIUqAAAAAKUYy1jE1UD9Dn-svC0l3e4EXeSK`;
        script.async = true;
        script.defer = true;
        document.head.appendChild(script);
        }
    },

    methods: {

        onVisibilityChange() {
            if (document.visibilityState == "hidden") {
                clearInterval(this.notificationInterval);
            }
            if (document.visibilityState == "visible") {
                this.loadNotificationCount();
                this.setNotificationInterval();
            }
        },

        setNotificationInterval() {
            this.notificationInterval = setInterval(
                this.loadNotificationCount,
                60000
            );
        },

        loadNotificationCount() {
            if (!this.$store.getters['auth/isLoggedIn']) {
                return;
            }

            return this.$http.get('notifications/count_unread')
                .then((res) => {
                    this.$store.commit('settings/setNotificationCount', res.body);
                })
        },
    }
}
</script>

<style>
html, body {
    margin: 0;
    padding: 0;
}

body { min-height: 100% }

#app {
  font-family: 'Rubik', Helvetica, Arial, sans-serif;
  min-height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
